.aboutContent {
  display: flex;
  flex-direction: column;
  background-color: #c0b0a2;
}

/* header portion */
.aboutHeader {
  padding: 30px;
  background-color: #5a2a27;
}
.aboutHeader h1 {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  color: #c0b0a2;
  letter-spacing: 3px;
  font-weight: 400;
}
.aboutHeader h3 {
  display: flex;
  justify-content: center;
  color: #c0b0a2;
  font-weight: 300;
  letter-spacing: 1px;
}

/* about us summary */
.aboutFamily img {
  float: left;
  margin-right: 1rem;
  height: 20%;
  width: 25%;
}
.aboutUs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
}
.aboutUs h1 {
  padding: 10px 10px 20px 10px;
  letter-spacing: 3px;
  font-weight: 400;
}
.aboutFamily p {
  padding: 10px 20px 10px 20px;
  line-height: 2.5em;
  letter-spacing: 0.5px;
}

/* MEDIA QUERIES */

/* tablet screens */
@media screen and (max-width: 1024px) {
  .aboutContent {
    margin: 0 auto;
  }

  /* About us summary  */
  .aboutFamily img {
    height: 70%;
    width: 50%;
  }
}

@media screen and (max-width: 728px) {
  .aboutFamily img {
    height: 90%;
    width: 70%;
    float: center;
  }
}
/* phone screen  */
@media screen and (max-width: 480px) {
  .aboutContent {
    margin: 0 auto;
  }

  /* header portion*/
  .aboutHeader h1 {
    display: flex;
    padding-bottom: 10px;
    justify-content: center;
    font-size: larger;
  }
  .aboutHeader h3 {
    display: flex;
    justify-content: center;
    font-size: medium;
  }

  /* about us summary  */
  .aboutFamily img {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin: 0px 0px 30px 0px;
  }
  .aboutUs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 0px 20px 0px;
  }
  .aboutUs h1 {
    display: flex;
    justify-content: center;
    padding: 10px 10px 20px 10px;
  }
}
