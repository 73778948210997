/* header */
.galleryHeader {
  display: flex;
  justify-content: center;
  background-color: #74433a;
  color: #c3b9ad;
  font-weight: 300;
  padding: 30px;
}

/* images */
.imageContainer {
  width: 100%;
  max-width: 100%;
}
.portrait {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 70rem;
  width: 50%;
  padding: 3rem;
  border-radius: 20px;
}
.landscape {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  height: 50rem;
  padding: 2rem;
  border-radius: 20px;
}
.imgDescription {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  font-weight: 400;
  font-size: large;
  letter-spacing: 3px;
}

/* tablets landscape */
@media screen and (max-width: 1024px) {
  /* header */
  .galleryHeader {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
  /* images */
  .portrait {
    display: block;
    margin-left: 1.5rem;
    margin-right: auto;
    height: 90vh;
    width: 90%;
    max-width: 100%;
    padding: 20px 0px 20px 0px;
    border-radius: 20px;
  }
  .landscape {
    display: block;
    margin-left: 1.5rem;
    margin-right: auto;
    height: 70vh;
    width: 90%;
    max-width: 100%;
  }
}

/* tablets*/
@media screen and (max-width: 768px) {
  /* header  */
  .galleryHeader {
    width: 90%;
    margin: 5px;
    max-width: 100%;
  }
  /* images */
  .portrait {
    display: block;
    margin-left: 1.5rem;
    margin-right: auto;
    height: 90vh;
    width: 90%;
    max-width: 100%;
    padding: 20px 0px 20px 0px;
    border-radius: 20px;
  }
  .landscape {
    display: block;
    margin-left: 1.5rem;
    margin-right: auto;
    height: 50vh;
    width: 90%;
    max-width: 100%;
  }
}

/* phone screens */
@media screen and (max-width: 480px) {
  /* header */
  .galleryHeader {
    font-weight: 300;
    font-size: large;
    padding: 30px;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    margin-bottom: 30px;
  }
  /* images */
  .portrait {
    display: block;
    margin-left: 1.5rem;
    margin-right: auto;
    height: 80vh;
    width: 90%;
    max-width: 100%;
    padding: 20px 0px 20px 0px;
    border-radius: 20px;
  }
  .landscape {
    display: block;
    margin-left: 1.5rem;
    margin-right: auto;
    height: 40vh;
    width: 90%;
    max-width: 100%;
  }
  .imgDescription {
    font-weight: 300;
    font-size: medium;
    letter-spacing: 1.5px;
  }
}
