.footerContent {
  padding: 50px 0px 50px 0px;
  margin: 0 auto;
  width: 100%;
  background-color: #816f67;
}
.footerContact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.contactItems .contactIcon {
  display: flex;
  align-items: center;
}
.footerContact img {
  height: 30px;
  width: 30px;
  padding: 10px 10px 10px 10px;
}
.contactItems {
  display: block;
  justify-content: space-between;
}
.facebook {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  right: auto;
}
.copyright {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

/* MEDIA QUERIES  */

/* tablet screens*/
@media screen and (max-width: 1024px) {
  .footerContent {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
  }
  .copyright {
    padding: 30px 0px 0px 0px;
    margin: 0px;
    font-size: medium;
  }
}

/* phone screen */
@media screen and (max-width: 480px) {
  .footerContent {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
  .copyright {
    padding: 30px 0px 30px 0px;
    font-size: small;
  }
}
