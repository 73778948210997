/* Main Content */
.homeContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

/* First Home Section  */
.homeIntro {
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-top: 30px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
}
.homeIntro h1 {
  display: flex;
  align-self: center;
  padding: 5rem;
  width: 20rem;
  margin-left: 50px;
  background-color: #5a2a27;
  color: #c0b0a2;
  opacity: 0.7;
  font-weight: 500;
  letter-spacing: 4px;
}
.firstPic {
  height: 35rem;
  width: 55rem;
  max-height: 80vh;
  margin-left: -200px;
  margin-right: 50px;
  z-index: -1;
}

/* Mottos */
.all-mottos {
  display: flex;
  align-self: center;
  max-width: 100%;
  padding: 30px;
  margin-bottom: 50px;
}
.motto {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  padding: 40px 20px 40px 20px;
  font-size: x-large;
  font-weight: 300;
  letter-spacing: 5px;
}
.care {
  font-weight: 400;
}
.understand,
.help {
  background-color: #816f67;
  color: #c3b9ad;
}

/* Inspiration/Explanation  */
.inspireReaders {
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  padding: 20px 0px 20px 60px;
  margin-bottom: 50px;
  background-color: #5a2a27;
}
.inspireReaders h1 {
  display: flex;
  justify-content: center;
  padding: 150px 60px 150px 60px;
  background-color: #c0b0a2;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1.5em;
  text-justify: distribute;
}
.inspireReaders p {
  display: flex;
  justify-content: center;
  align-self: center;
  max-width: 80%;
  padding: 30px 30px 10px 30px;
  background-color: #8f726a;
  line-height: 2.5em;
  letter-spacing: 2px;
  text-justify: inter-word;
}

/* Steps to Contact  */
.all-steps {
  display: flex;
  align-self: center;
  max-width: 100%;
  padding: 30px;
}
.step {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}
.step h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px;
  width: 15rem;
  height: 15rem;
  background-color: #8f726a;
  color: #c0b0a2;
  font-weight: 300;
  letter-spacing: 1.5px;
}
.step p {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px;
  width: 15rem;
  height: 15rem;
  background-color: #c0b0a2;
  color: #42322f;
  line-height: 1.7em;
  font-size: larger;
  font-weight: 400;
  letter-spacing: 1.5px;
}

/* Testimonials */

.testimonials {
  display: flex;
  align-self: center;
  flex-direction: column;
  max-width: 90%;
  padding: 40px 20px 20px 20px;
  margin-bottom: 50px;
}
.testimonials h1 {
  font-weight: 400;
}
.groupTestimonials {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 30px;
}
.testimonialsFirstGroup {
  display: flex;
  flex-direction: column;
  line-height: 1.7em;
  font-weight: 300;
  font-size: medium;
  letter-spacing: 1px;
}
.testimonialsSecondGroup {
  display: flex;
  flex-direction: column;
  line-height: 1.7em;
  font-size: medium;
  font-weight: 300;
  letter-spacing: 1px;
}
.testimonial {
  padding: 10px;
}

/* MEDIA QUERIES  */

/* tablet screens*/
@media screen and (max-width: 1024px) {
  .homeContent {
    width: 100%;
    max-width: 100%;
  }
  /* First Home Section  */
  .homeIntro {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    height: 50vh;
    margin-top: 50px;
  }
  .homeIntro h1 {
    position: absolute;
    left: 0;
    padding: 50px 40px 50px 40px;
    width: 400px;
    font-size: x-large;
    margin-left: 0px;
    font-weight: 300;
    z-index: 9;
  }
  .firstPic {
    position: absolute;
    right: 0;
    margin: 0px;
    height: 500px;
    width: 700px;
    max-width: 100%;
    z-index: -1;
  }
  /* Mottos */
  .all-mottos {
    padding: 30px 0px;
    margin: 25px 0px 25px 0px;
    width: 100%;
  }
  .motto {
    width: 300px;
    padding: 30px 10px 30px 10px;
  }
  .care {
    font-weight: 300;
    font-size: x-large;
  }
  .understand,
  .help {
    text-align: center;
    font-size: x-large;
    font-weight: 300;
  }

  /* Inspiration/Explanation  */
  .inspireReaders {
    padding: 10px 20px 10px 20px;
    margin-bottom: 40px;
  }
  .inspireReaders h1 {
    padding: 90px 80px 90px 80px;
    font-weight: 200;
    font-size: xx-large;
  }
  .inspireReaders p {
    max-width: 80%;
    padding: 40px 30px 40px 30px;
    line-height: 2;
  }

  /* Steps to Contact  */
  .all-steps {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    padding: 0px;
    margin: 30px 0px 0px 40px;
  }
  .step {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    padding: 0px;
    margin: -20px;
  }
  .step h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px;
    width: 250px;
    height: 150px;
  }
  .step p {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px;
    width: 250px;
    height: 150px;
  }

  /* Testimonials */

  .testimonials {
    display: flex;
    align-self: center;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    padding: 40px 20px 20px 20px;
    margin-bottom: 50px;
  }
  .testimonials h1 {
    font-weight: 400;
    margin-left: 2rem;
  }
  .groupTestimonials {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 30px;
  }
  .testimonialsFirstGroup {
    display: flex;
    flex-direction: column;
    line-height: 1.7em;
    font-weight: 300;
    letter-spacing: 1px;
    font-size: medium;
  }
  .testimonialsSecondGroup {
    display: flex;
    flex-direction: column;
    font-size: medium;
    font-weight: 300;
    line-height: 1.7em;
    letter-spacing: 1px;
  }
  .testimonial {
    padding: 10px;
  }
}

/* phone screens  */
@media screen and (max-width: 750px) {
  /* First Home Section */
  .homeIntro {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    height: 55vh;
    margin: 10px 0px 10px 0px;
  }
  .firstPic {
    position: block;
    margin-right: auto;
    margin-left: auto;
    max-width: 100%;
    width: 100%;
    height: 55vh;
  }
  .homeIntro h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 35vh;
    margin-top: 0px;
    left: -1px;
    margin-left: 0;
    padding: 0px 0px 0px 6px;
    color: #fff1e4;
    opacity: 0.8;
    font-weight: 200;
    font-size: x-large;
    line-height: 2;
  }

  /* Mottos */
  .all-mottos {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0px;
    width: 100%;
    max-width: 100%;
    margin: 30px 0px 30px 0px;
  }
  .motto {
    max-width: 100%;
    width: 100%;
    padding: 0px;
  }
  .care {
    margin: 30px 0px 30px 0px;
    font-weight: 300;
  }
  .understand,
  .help {
    padding: 50px 0px 50px 0px;
  }

  /* Inspiration/Explanation  */
  .inspireReaders {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    right: 0;
    left: 0;
    padding: 30px 10px 30px 10px;
    max-width: 100%;
  }
  .inspireReaders h1 {
    display: flex;
    justify-content: center;
    padding: 100px 40px;
    background-color: #c0b0a2;
  }
  .inspireReaders p {
    max-width: 100%;
    padding: 30px;
  }

  /* Steps to Contact  */
  .all-steps {
    display: none;
  }

  /* Testimonials */
  .testimonials {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 20px 10px 20px 10px;
    margin-bottom: 10px;
  }
  .testimonials h1 {
    float: left;
    margin-left: 10px;
  }
  .groupTestimonials {
    display: flex;
    flex-direction: column;
    padding: 0px 15px 0px 15px;
    line-height: 1.5;
  }
  .testimonialsFirstGroup {
    padding: 0px;
    line-height: 2em;
  }
  .testimonialsSecondGroup {
    font-weight: 300;
    line-height: 2em;
  }
}
