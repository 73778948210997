@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap);
* {
  font-family: "Montserrat";
  color: #0a0807;
}

html {
  background-color: #c0b0a2;
}

/* navbar container */
.navbar {
  padding: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  max-width: 80%;
  margin: 0 auto;
  border-bottom: 1px soild #523e3a;
}

/* custom logo */
.navbar .em {
  margin-top: 20px;
  color: #523e3a;
  font-weight: 500;
  letter-spacing: 3px;
  font-size: x-large;
}
.navbar .as {
  color: #523e3a;
  margin-top: -20px;
  font-weight: 100;
  font-size: large;
}

/* mobile menu */
.fa-bars {
  color: #523e3a;
}
.nav-links-mobile {
  display: none;
}
.menu-icon {
  display: none;
}
.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vh;
  -webkit-justify-content: end;
          justify-content: end;
  margin-left: auto;
}

/* desktop nav links */
.nav-links {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: 16px;
  text-decoration: none;
  padding: 15px;
  color: #523e3a;
  font-weight: 400;
  font-size: large;
}
.nav-links:hover {
  border-bottom: 1px solid #523e3a;
}

/* MEDIA QUERIES */

/* tablet screens */
@media only screen and (max-width: 1024px) {
  /* nav container */
  .navbar {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    overflow: hidden;
    -webkit-align-items: center;
            align-items: center;
    padding: 0px;
  }
  /* nav logo */
  .navbar .navbar-logo {
    top: 0;
    left: 0;
    padding: 2px;
  }
  /* desktop links */
  .navbar .links {
    display: none;
  }

  /* mobile links */
  .menu {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: right;
            justify-content: right;
    margin-right: 5px;
  }
  .NavBarItems {
    position: relative;
  }
  .nav-menu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 500px;
    position: absolute;
    top: 85px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .nav-menu.active {
    background-color: #523e3a;
    left: 0;
    opacity: 10;
    transition: all 0.5s ease;
    z-index: 10;
  }
  .nav-links {
    text-align: center;
    font-size: larger;
    width: 87%;
    display: table;
    color: #c3b9ad;
    padding: 2.5rem;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .nav-links:hover {
    background-color: #523e3a;
    color: #c3b9ad;
    border-radius: 0;
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
  .fa-times {
    color: #523e3a;
    font-size: 2rem;
    margin-top: 20px;
  }
  .fa-bars {
    margin-top: 20px;
  }
  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 70%;
    background-color: #c3b9ad;
    color: #523e3a;
    font-size: 1.5rem;
    text-decoration: none;
  }
  .nav-links-mobile:hover {
    background: #fff;
    color: #523e3a;
    transition: 250ms;
    cursor: pointer;
  }
  .contactUsButton {
    display: none;
  }
}

/* phone screen */
@media screen and (max-width: 480px) {
  /* navbar container */
  .navbar {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    overflow: hidden;
    -webkit-align-items: center;
            align-items: center;
    padding: 0px;
  }
  /* nav logo */
  .navbar .navbar-logo {
    top: 0;
    left: 0;
    padding: 2px;
  }
  /* desktop links */
  .navbar .links {
    display: none;
  }
  .menu {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: right;
            justify-content: right;
    margin-right: 5px;
  }
  /* mobile links */
  .nav-menu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 101%;
    height: 500px;
    position: absolute;
    top: 85px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .nav-menu.active {
    background-color: #523e3a;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-links {
    text-align: center;
    font-size: larger;
    width: 75%;
    display: table;
    color: #c3b9ad;
    padding: 2.5rem;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .nav-links:hover {
    background-color: #523e3a;
    color: #c3b9ad;
    border-radius: 0;
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
  .fa-times {
    color: #523e3a;
    font-size: 2rem;
    margin-top: 20px;
  }
  .fa-bars {
    margin-top: 20px;
  }
  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 70%;
    background-color: #c3b9ad;
    color: #523e3a;
    font-size: 1.5rem;
    text-decoration: none;
  }
  .nav-links-mobile:hover {
    background: #fff;
    color: #523e3a;
    transition: 250ms;
    cursor: pointer;
  }
  .contactUsButton {
    display: none;
  }
}

/* Main Content */
.homeContent {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0 auto;
}

/* First Home Section  */
.homeIntro {
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-top: 30px;
  margin-bottom: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.homeIntro h1 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: center;
          align-self: center;
  padding: 5rem;
  width: 20rem;
  margin-left: 50px;
  background-color: #5a2a27;
  color: #c0b0a2;
  opacity: 0.7;
  font-weight: 500;
  letter-spacing: 4px;
}
.firstPic {
  height: 35rem;
  width: 55rem;
  max-height: 80vh;
  margin-left: -200px;
  margin-right: 50px;
  z-index: -1;
}

/* Mottos */
.all-mottos {
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: center;
          align-self: center;
  max-width: 100%;
  padding: 30px;
  margin-bottom: 50px;
}
.motto {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 350px;
  padding: 40px 20px 40px 20px;
  font-size: x-large;
  font-weight: 300;
  letter-spacing: 5px;
}
.care {
  font-weight: 400;
}
.understand,
.help {
  background-color: #816f67;
  color: #c3b9ad;
}

/* Inspiration/Explanation  */
.inspireReaders {
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: flex-end;
          align-self: flex-end;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 20px 0px 20px 60px;
  margin-bottom: 50px;
  background-color: #5a2a27;
}
.inspireReaders h1 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 150px 60px 150px 60px;
  background-color: #c0b0a2;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1.5em;
  text-justify: distribute;
}
.inspireReaders p {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-self: center;
          align-self: center;
  max-width: 80%;
  padding: 30px 30px 10px 30px;
  background-color: #8f726a;
  line-height: 2.5em;
  letter-spacing: 2px;
  text-justify: inter-word;
}

/* Steps to Contact  */
.all-steps {
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: center;
          align-self: center;
  max-width: 100%;
  padding: 30px;
}
.step {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  max-width: 100%;
}
.step h1 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 80px;
  width: 15rem;
  height: 15rem;
  background-color: #8f726a;
  color: #c0b0a2;
  font-weight: 300;
  letter-spacing: 1.5px;
}
.step p {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 80px;
  width: 15rem;
  height: 15rem;
  background-color: #c0b0a2;
  color: #42322f;
  line-height: 1.7em;
  font-size: larger;
  font-weight: 400;
  letter-spacing: 1.5px;
}

/* Testimonials */

.testimonials {
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: center;
          align-self: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  max-width: 90%;
  padding: 40px 20px 20px 20px;
  margin-bottom: 50px;
}
.testimonials h1 {
  font-weight: 400;
}
.groupTestimonials {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  padding: 30px;
}
.testimonialsFirstGroup {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  line-height: 1.7em;
  font-weight: 300;
  font-size: medium;
  letter-spacing: 1px;
}
.testimonialsSecondGroup {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  line-height: 1.7em;
  font-size: medium;
  font-weight: 300;
  letter-spacing: 1px;
}
.testimonial {
  padding: 10px;
}

/* MEDIA QUERIES  */

/* tablet screens*/
@media screen and (max-width: 1024px) {
  .homeContent {
    width: 100%;
    max-width: 100%;
  }
  /* First Home Section  */
  .homeIntro {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    max-width: 100%;
    height: 50vh;
    margin-top: 50px;
  }
  .homeIntro h1 {
    position: absolute;
    left: 0;
    padding: 50px 40px 50px 40px;
    width: 400px;
    font-size: x-large;
    margin-left: 0px;
    font-weight: 300;
    z-index: 9;
  }
  .firstPic {
    position: absolute;
    right: 0;
    margin: 0px;
    height: 500px;
    width: 700px;
    max-width: 100%;
    z-index: -1;
  }
  /* Mottos */
  .all-mottos {
    padding: 30px 0px;
    margin: 25px 0px 25px 0px;
    width: 100%;
  }
  .motto {
    width: 300px;
    padding: 30px 10px 30px 10px;
  }
  .care {
    font-weight: 300;
    font-size: x-large;
  }
  .understand,
  .help {
    text-align: center;
    font-size: x-large;
    font-weight: 300;
  }

  /* Inspiration/Explanation  */
  .inspireReaders {
    padding: 10px 20px 10px 20px;
    margin-bottom: 40px;
  }
  .inspireReaders h1 {
    padding: 90px 80px 90px 80px;
    font-weight: 200;
    font-size: xx-large;
  }
  .inspireReaders p {
    max-width: 80%;
    padding: 40px 30px 40px 30px;
    line-height: 2;
  }

  /* Steps to Contact  */
  .all-steps {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    max-width: 100%;
    padding: 0px;
    margin: 30px 0px 0px 40px;
  }
  .step {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100%;
    max-width: 100%;
    padding: 0px;
    margin: -20px;
  }
  .step h1 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 100px;
    width: 250px;
    height: 150px;
  }
  .step p {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 100px;
    width: 250px;
    height: 150px;
  }

  /* Testimonials */

  .testimonials {
    display: -webkit-flex;
    display: flex;
    -webkit-align-self: center;
            align-self: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    max-width: 100%;
    padding: 40px 20px 20px 20px;
    margin-bottom: 50px;
  }
  .testimonials h1 {
    font-weight: 400;
    margin-left: 2rem;
  }
  .groupTestimonials {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    padding: 30px;
  }
  .testimonialsFirstGroup {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    line-height: 1.7em;
    font-weight: 300;
    letter-spacing: 1px;
    font-size: medium;
  }
  .testimonialsSecondGroup {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-size: medium;
    font-weight: 300;
    line-height: 1.7em;
    letter-spacing: 1px;
  }
  .testimonial {
    padding: 10px;
  }
}

/* phone screens  */
@media screen and (max-width: 750px) {
  /* First Home Section */
  .homeIntro {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    max-width: 100%;
    height: 55vh;
    margin: 10px 0px 10px 0px;
  }
  .firstPic {
    position: block;
    margin-right: auto;
    margin-left: auto;
    max-width: 100%;
    width: 100%;
    height: 55vh;
  }
  .homeIntro h1 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 35vh;
    margin-top: 0px;
    left: -1px;
    margin-left: 0;
    padding: 0px 0px 0px 6px;
    color: #fff1e4;
    opacity: 0.8;
    font-weight: 200;
    font-size: x-large;
    line-height: 2;
  }

  /* Mottos */
  .all-mottos {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 0 auto;
    padding: 0px;
    width: 100%;
    max-width: 100%;
    margin: 30px 0px 30px 0px;
  }
  .motto {
    max-width: 100%;
    width: 100%;
    padding: 0px;
  }
  .care {
    margin: 30px 0px 30px 0px;
    font-weight: 300;
  }
  .understand,
  .help {
    padding: 50px 0px 50px 0px;
  }

  /* Inspiration/Explanation  */
  .inspireReaders {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 0 auto;
    right: 0;
    left: 0;
    padding: 30px 10px 30px 10px;
    max-width: 100%;
  }
  .inspireReaders h1 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 100px 40px;
    background-color: #c0b0a2;
  }
  .inspireReaders p {
    max-width: 100%;
    padding: 30px;
  }

  /* Steps to Contact  */
  .all-steps {
    display: none;
  }

  /* Testimonials */
  .testimonials {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    max-width: 100%;
    padding: 20px 10px 20px 10px;
    margin-bottom: 10px;
  }
  .testimonials h1 {
    float: left;
    margin-left: 10px;
  }
  .groupTestimonials {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 0px 15px 0px 15px;
    line-height: 1.5;
  }
  .testimonialsFirstGroup {
    padding: 0px;
    line-height: 2em;
  }
  .testimonialsSecondGroup {
    font-weight: 300;
    line-height: 2em;
  }
}

/* header */
.galleryHeader {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #74433a;
  color: #c3b9ad;
  font-weight: 300;
  padding: 30px;
}

/* images */
.imageContainer {
  width: 100%;
  max-width: 100%;
}
.portrait {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 70rem;
  width: 50%;
  padding: 3rem;
  border-radius: 20px;
}
.landscape {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  height: 50rem;
  padding: 2rem;
  border-radius: 20px;
}
.imgDescription {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 0px;
  font-weight: 400;
  font-size: large;
  letter-spacing: 3px;
}

/* tablets landscape */
@media screen and (max-width: 1024px) {
  /* header */
  .galleryHeader {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
  /* images */
  .portrait {
    display: block;
    margin-left: 1.5rem;
    margin-right: auto;
    height: 90vh;
    width: 90%;
    max-width: 100%;
    padding: 20px 0px 20px 0px;
    border-radius: 20px;
  }
  .landscape {
    display: block;
    margin-left: 1.5rem;
    margin-right: auto;
    height: 70vh;
    width: 90%;
    max-width: 100%;
  }
}

/* tablets*/
@media screen and (max-width: 768px) {
  /* header  */
  .galleryHeader {
    width: 90%;
    margin: 5px;
    max-width: 100%;
  }
  /* images */
  .portrait {
    display: block;
    margin-left: 1.5rem;
    margin-right: auto;
    height: 90vh;
    width: 90%;
    max-width: 100%;
    padding: 20px 0px 20px 0px;
    border-radius: 20px;
  }
  .landscape {
    display: block;
    margin-left: 1.5rem;
    margin-right: auto;
    height: 50vh;
    width: 90%;
    max-width: 100%;
  }
}

/* phone screens */
@media screen and (max-width: 480px) {
  /* header */
  .galleryHeader {
    font-weight: 300;
    font-size: large;
    padding: 30px;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    margin-bottom: 30px;
  }
  /* images */
  .portrait {
    display: block;
    margin-left: 1.5rem;
    margin-right: auto;
    height: 80vh;
    width: 90%;
    max-width: 100%;
    padding: 20px 0px 20px 0px;
    border-radius: 20px;
  }
  .landscape {
    display: block;
    margin-left: 1.5rem;
    margin-right: auto;
    height: 40vh;
    width: 90%;
    max-width: 100%;
  }
  .imgDescription {
    font-weight: 300;
    font-size: medium;
    letter-spacing: 1.5px;
  }
}

/* header container */
.contactHeader {
  padding: 3em;
  background-color: #5a2a27;
}
.contactHeader h1 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  min-width: 1px;
  width: 94%;
  padding: 0.5em;
  margin: 0 auto;
  color: #c3b9ad;
  font-weight: 300;
  letter-spacing: 1px;
}

/* contact form headers */
.contactFormHeader {
  background-color: #8f726a;
  max-width: 70%;
  min-width: 1px;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  margin: 0 auto;
  margin-top: 30px;
  padding: 10px 30px 30px 30px;
}
.contactFormHeader h2 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 10px;
  margin-top: 30px;
  font-weight: 300;
  letter-spacing: 1px;
}
.contactFormHeader h3 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 5px;
  font-weight: 300;
}
/* actual form  */
.contactForm {
  max-width: 70%;
  margin: 0 auto;
  margin-bottom: 30px;
  padding: 10px 30px 30px 30px;
  background-color: #8f726a;
  text-align: center;
}
.contactForm label {
  display: block;
  padding-top: 10px;
  text-align: left;
  font-weight: 300;
  font-size: large;
  letter-spacing: 1px;
}
.contactForm h2 {
  margin: 30px;
  text-align: center;
  font-size: 30px;
}
.contactForm input,
.contactForm textarea {
  display: block;
  width: 100%;
  padding: 15px 10px;
  margin: 10px 0 20px 0;
  background-color: #c3b9ad;
  border: 1px solid #918378;
  box-sizing: border-box;
}

.contactForm input:focus,
.contactForm textarea {
  outline: none;
  border: 3px solid #918378;
}

.contactForm button {
  padding: 20px;
  margin: 30px 0px 15px 0px;
  width: 60%;
  cursor: pointer;
  border-radius: 8px;
  border: 5px solid #523e3a;
  background-color: #c3b9ad;
  color: #523e3a;
  border: 0;
  font-size: large;
  font-weight: 400;
  letter-spacing: 1px;
}

/* MEDIA QUERIES  */

@media screen and (max-width: 1140px) {
  /* header container */
  .contactHeader h1 {
    font-size: x-large;
    white-space: nowrap;
  }
}

/* landscape tablets */
@media screen and (max-width: 922) {
  /* header container */
  .contactHeader h1 {
    font-size: larger;
    margin: 10;
    font-weight: 400;
  }
  /* contact form headers */
  .contactFormHeader {
    width: 90%;
    max-width: 100%;
    margin: 10 0;
    font-size: medium;
    font-weight: 300;
  }
  .contactForm {
    width: 90%;
    max-width: 100%;
    margin: 10;
    padding: 10px 30px 30px 30px;
    margin-bottom: 30px;
  }
  .contactForm h2 {
    margin: 30px;
    text-align: center;
    font-size: 30px;
  }
}

/* portrait tablets */
@media screen and (max-width: 728px) {
  /* header container */
  .contactHeader {
    padding: 2em;
  }
  /* contact form headers  */
  .contactHeader h1 {
    font-size: larger;
  }
  .contactFormHeader {
    max-width: 100%;
  }
  .contactForm {
    max-width: 100%;
  }
}

/* phone screens */
@media screen and (max-width: 615px) {
  /* header container  */
  .contactHeader h1 {
    font-size: smaller;
  }
  /* contact form headers  */
  .contactFormHeader {
    width: 100%;
    margin: 0;
    margin-top: 30px;
    padding: 10px 30px 30px 30px;
  }
  .contactForm {
    max-width: 100%;
  }
}

.aboutContent {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: #c0b0a2;
}

/* header portion */
.aboutHeader {
  padding: 30px;
  background-color: #5a2a27;
}
.aboutHeader h1 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-bottom: 10px;
  color: #c0b0a2;
  letter-spacing: 3px;
  font-weight: 400;
}
.aboutHeader h3 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  color: #c0b0a2;
  font-weight: 300;
  letter-spacing: 1px;
}

/* about us summary */
.aboutFamily img {
  float: left;
  margin-right: 1rem;
  height: 20%;
  width: 25%;
}
.aboutUs {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 30px;
}
.aboutUs h1 {
  padding: 10px 10px 20px 10px;
  letter-spacing: 3px;
  font-weight: 400;
}
.aboutFamily p {
  padding: 10px 20px 10px 20px;
  line-height: 2.5em;
  letter-spacing: 0.5px;
}

/* MEDIA QUERIES */

/* tablet screens */
@media screen and (max-width: 1024px) {
  .aboutContent {
    margin: 0 auto;
  }

  /* About us summary  */
  .aboutFamily img {
    height: 70%;
    width: 50%;
  }
}

@media screen and (max-width: 728px) {
  .aboutFamily img {
    height: 90%;
    width: 70%;
    float: center;
  }
}
/* phone screen  */
@media screen and (max-width: 480px) {
  .aboutContent {
    margin: 0 auto;
  }

  /* header portion*/
  .aboutHeader h1 {
    display: -webkit-flex;
    display: flex;
    padding-bottom: 10px;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: larger;
  }
  .aboutHeader h3 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: medium;
  }

  /* about us summary  */
  .aboutFamily img {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    height: 100%;
    width: 100%;
    margin: 0px 0px 30px 0px;
  }
  .aboutUs {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 10px 0px 20px 0px;
  }
  .aboutUs h1 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 10px 10px 20px 10px;
  }
}

.footerContent {
  padding: 50px 0px 50px 0px;
  margin: 0 auto;
  width: 100%;
  background-color: #816f67;
}
.footerContact {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.contactItems .contactIcon {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.footerContact img {
  height: 30px;
  width: 30px;
  padding: 10px 10px 10px 10px;
}
.contactItems {
  display: block;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.facebook {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin: 0 auto;
  right: auto;
}
.copyright {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 30px;
}

/* MEDIA QUERIES  */

/* tablet screens*/
@media screen and (max-width: 1024px) {
  .footerContent {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
  }
  .copyright {
    padding: 30px 0px 0px 0px;
    margin: 0px;
    font-size: medium;
  }
}

/* phone screen */
@media screen and (max-width: 480px) {
  .footerContent {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
  .copyright {
    padding: 30px 0px 30px 0px;
    font-size: small;
  }
}

.spinner {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.spinner:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #816f67;
  border-color: #816f67 transparent #816f67 transparent;
  -webkit-animation: spinner 1.2s linear infinite;
          animation: spinner 1.2s linear infinite;
}
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

