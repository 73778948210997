/* header container */
.contactHeader {
  padding: 3em;
  background-color: #5a2a27;
}
.contactHeader h1 {
  display: flex;
  justify-content: center;
  min-width: 1px;
  width: 94%;
  padding: 0.5em;
  margin: 0 auto;
  color: #c3b9ad;
  font-weight: 300;
  letter-spacing: 1px;
}

/* contact form headers */
.contactFormHeader {
  background-color: #8f726a;
  max-width: 70%;
  min-width: 1px;
  flex: 1 1 auto;
  margin: 0 auto;
  margin-top: 30px;
  padding: 10px 30px 30px 30px;
}
.contactFormHeader h2 {
  display: flex;
  justify-content: center;
  padding: 10px;
  margin-top: 30px;
  font-weight: 300;
  letter-spacing: 1px;
}
.contactFormHeader h3 {
  display: flex;
  justify-content: center;
  padding: 5px;
  font-weight: 300;
}
/* actual form  */
.contactForm {
  max-width: 70%;
  margin: 0 auto;
  margin-bottom: 30px;
  padding: 10px 30px 30px 30px;
  background-color: #8f726a;
  text-align: center;
}
.contactForm label {
  display: block;
  padding-top: 10px;
  text-align: left;
  font-weight: 300;
  font-size: large;
  letter-spacing: 1px;
}
.contactForm h2 {
  margin: 30px;
  text-align: center;
  font-size: 30px;
}
.contactForm input,
.contactForm textarea {
  display: block;
  width: 100%;
  padding: 15px 10px;
  margin: 10px 0 20px 0;
  background-color: #c3b9ad;
  border: 1px solid #918378;
  box-sizing: border-box;
}

.contactForm input:focus,
.contactForm textarea {
  outline: none;
  border: 3px solid #918378;
}

.contactForm button {
  padding: 20px;
  margin: 30px 0px 15px 0px;
  width: 60%;
  cursor: pointer;
  border-radius: 8px;
  border: 5px solid #523e3a;
  background-color: #c3b9ad;
  color: #523e3a;
  border: 0;
  font-size: large;
  font-weight: 400;
  letter-spacing: 1px;
}

/* MEDIA QUERIES  */

@media screen and (max-width: 1140px) {
  /* header container */
  .contactHeader h1 {
    font-size: x-large;
    white-space: nowrap;
  }
}

/* landscape tablets */
@media screen and (max-width: 922) {
  /* header container */
  .contactHeader h1 {
    font-size: larger;
    margin: 10;
    font-weight: 400;
  }
  /* contact form headers */
  .contactFormHeader {
    width: 90%;
    max-width: 100%;
    margin: 10 0;
    font-size: medium;
    font-weight: 300;
  }
  .contactForm {
    width: 90%;
    max-width: 100%;
    margin: 10;
    padding: 10px 30px 30px 30px;
    margin-bottom: 30px;
  }
  .contactForm h2 {
    margin: 30px;
    text-align: center;
    font-size: 30px;
  }
}

/* portrait tablets */
@media screen and (max-width: 728px) {
  /* header container */
  .contactHeader {
    padding: 2em;
  }
  /* contact form headers  */
  .contactHeader h1 {
    font-size: larger;
  }
  .contactFormHeader {
    max-width: 100%;
  }
  .contactForm {
    max-width: 100%;
  }
}

/* phone screens */
@media screen and (max-width: 615px) {
  /* header container  */
  .contactHeader h1 {
    font-size: smaller;
  }
  /* contact form headers  */
  .contactFormHeader {
    width: 100%;
    margin: 0;
    margin-top: 30px;
    padding: 10px 30px 30px 30px;
  }
  .contactForm {
    max-width: 100%;
  }
}
