/* navbar container */
.navbar {
  padding: 30px;
  display: flex;
  align-items: center;
  max-width: 80%;
  margin: 0 auto;
  border-bottom: 1px soild #523e3a;
}

/* custom logo */
.navbar .em {
  margin-top: 20px;
  color: #523e3a;
  font-weight: 500;
  letter-spacing: 3px;
  font-size: x-large;
}
.navbar .as {
  color: #523e3a;
  margin-top: -20px;
  font-weight: 100;
  font-size: large;
}

/* mobile menu */
.fa-bars {
  color: #523e3a;
}
.nav-links-mobile {
  display: none;
}
.menu-icon {
  display: none;
}
.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vh;
  justify-content: end;
  margin-left: auto;
}

/* desktop nav links */
.nav-links {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  text-decoration: none;
  padding: 15px;
  color: #523e3a;
  font-weight: 400;
  font-size: large;
}
.nav-links:hover {
  border-bottom: 1px solid #523e3a;
}

/* MEDIA QUERIES */

/* tablet screens */
@media only screen and (max-width: 1024px) {
  /* nav container */
  .navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    align-items: center;
    padding: 0px;
  }
  /* nav logo */
  .navbar .navbar-logo {
    top: 0;
    left: 0;
    padding: 2px;
  }
  /* desktop links */
  .navbar .links {
    display: none;
  }

  /* mobile links */
  .menu {
    display: flex;
    justify-content: right;
    margin-right: 5px;
  }
  .NavBarItems {
    position: relative;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
    position: absolute;
    top: 85px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .nav-menu.active {
    background-color: #523e3a;
    left: 0;
    opacity: 10;
    transition: all 0.5s ease;
    z-index: 10;
  }
  .nav-links {
    text-align: center;
    font-size: larger;
    width: 87%;
    display: table;
    color: #c3b9ad;
    padding: 2.5rem;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .nav-links:hover {
    background-color: #523e3a;
    color: #c3b9ad;
    border-radius: 0;
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
  .fa-times {
    color: #523e3a;
    font-size: 2rem;
    margin-top: 20px;
  }
  .fa-bars {
    margin-top: 20px;
  }
  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 70%;
    background-color: #c3b9ad;
    color: #523e3a;
    font-size: 1.5rem;
    text-decoration: none;
  }
  .nav-links-mobile:hover {
    background: #fff;
    color: #523e3a;
    transition: 250ms;
    cursor: pointer;
  }
  .contactUsButton {
    display: none;
  }
}

/* phone screen */
@media screen and (max-width: 480px) {
  /* navbar container */
  .navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    align-items: center;
    padding: 0px;
  }
  /* nav logo */
  .navbar .navbar-logo {
    top: 0;
    left: 0;
    padding: 2px;
  }
  /* desktop links */
  .navbar .links {
    display: none;
  }
  .menu {
    display: flex;
    justify-content: right;
    margin-right: 5px;
  }
  /* mobile links */
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 101%;
    height: 500px;
    position: absolute;
    top: 85px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .nav-menu.active {
    background-color: #523e3a;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-links {
    text-align: center;
    font-size: larger;
    width: 75%;
    display: table;
    color: #c3b9ad;
    padding: 2.5rem;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .nav-links:hover {
    background-color: #523e3a;
    color: #c3b9ad;
    border-radius: 0;
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
  .fa-times {
    color: #523e3a;
    font-size: 2rem;
    margin-top: 20px;
  }
  .fa-bars {
    margin-top: 20px;
  }
  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 70%;
    background-color: #c3b9ad;
    color: #523e3a;
    font-size: 1.5rem;
    text-decoration: none;
  }
  .nav-links-mobile:hover {
    background: #fff;
    color: #523e3a;
    transition: 250ms;
    cursor: pointer;
  }
  .contactUsButton {
    display: none;
  }
}
